import { Observable, map } from 'rxjs'

import { NewsCard } from './shared/types'

import { Injectable, inject } from '@angular/core'

import { ApiService, BaseHttpParams } from 'src/app/core/abstracts/api.service'
import { ResponseInterface } from 'src/app/core/interfaces/response'
import { LangService } from 'src/app/core/services/lang.service'

@Injectable({
  providedIn: 'root',
})
export class NewsService extends ApiService {
  private langService: LangService = inject(LangService)

  getNews(params: BaseHttpParams): Observable<ResponseInterface<NewsCard>> {
    return this.getList<NewsCard>('/front/news', params, (itemData: NewsCard) => NewsCard.fromJson<NewsCard>(itemData, this.langService))
  }

  getNewsById(id: number): Observable<NewsCard> {
    return this.getById<NewsCard>('/front/news', id, (itemData: NewsCard) => NewsCard.fromJson<NewsCard>(itemData, this.langService))
  }

  getSimilarNews(id: number): Observable<ResponseInterface<NewsCard>> {
    return this.http.get<ResponseInterface<NewsCard>>(`${this.baseUrl}/front/news/${id}/similar`).pipe(
      map((data: ResponseInterface<NewsCard>) => ({
        total: data.total,
        data: data.data.map((itemData) => NewsCard.fromJson<NewsCard>(itemData, this.langService)),
      })),
    )
  }
}
