import { MetaModel } from '@app/core/models/meta.models'

import { TranslatableModel } from 'src/app/core/abstracts/translate-model'

export class NewsCard extends TranslatableModel<NewsCardTranslationData> {
  image!: string
  shortDesc!: string
  publishedAt!: string
  id!: number
  slug!: string

  get title(): string {
    return this.translation?.title || this.translation?.data?.data.title
  }
}

export interface NewsCardTranslationData {
  blocks: any
  data: NewsCardTranslationDeepData
}

export interface NewsCardTranslationDeepData {
  blocks: any[]
  content: string
  meta: MetaModel
  title: string
}
